(function ($) {

    "use strict";

    var Form = function (el, options) {
        this.$form = $(el);
        this.options = options;
        this.faceCount = 0;
        this.init();
    };

    Form.prototype = {
        // large_form_shown: false,
        init: function () {
            this.appendOptions(); // attach options to the class object
            if (this.with_location) {
                this.$countries = this.$form.find('#countries');
                this.$provinces = this.$form.find('#provinces');
                this.$provinces_str = this.$form.find('#provinces-str');
                this.$towns = this.$form.find('#towns');
                this.$towns_str = this.$form.find('#towns-str');
                this.$schools = this.$form.find('#schools');
                this.$schools_datalist = this.$form.find('#schools-datalist');
            }
            this.bindEvents();
        },
        // toma las opciones enviadas desde la vista
        appendOptions: function () {
            var self = this;
            $.each(this.options, function(k, v) {
                self[k] = v; // this.options.foo = this.foo
            });
        },
        // bindeo eventos
        bindEvents: function () {
            var self = this;
            // al submitear, antes validar
            this.$form.on('submit', function(e) {
                if (self.allowDefault) {
                    if (!self.validateForm()) {
                        e.preventDefault();
                    } else if (typeof self.customSubmit !== 'undefined' && !self.canSubmit) {
                        self.customSubmit(e, this);
                    }
                } else {
                    e.preventDefault();
                    if (self.validateForm()) { self.submitForm(); }
                }
            }).on('input change focus', '.form-control', function() {
                $(this).removeClass('is-invalid');
            });
            // si existe el select de provincias y ciudades
            if (this.with_location) {
                // al cambiar de país, cargar provincias
                this.$countries.on('change', function() {
                    var country = $(this).val();
                    if (country == 1) {
                        self.$provinces_str
                            .attr({ 'hidden': true, 'disabled': true })
                            .removeClass('is-invalid');
                        self.$provinces
                            .removeAttr('disabled hidden');
                        self.$towns_str
                            .attr({ 'hidden': true, 'disabled': true })
                            .removeClass('is-invalid');
                        self.$towns
                            .removeAttr('disabled hidden');
                    } else {
                        self.$provinces
                            .attr({ 'hidden': true, 'disabled': true })
                            .removeClass('is-invalid');
                        self.$provinces_str
                            .removeAttr('disabled hidden');
                        self.$towns
                            .attr({ 'hidden': true, 'disabled': true })
                            .removeClass('is-invalid');
                        self.$towns_str
                            .removeAttr('disabled hidden');
                    }
                });
                // al cambiar de provincia, cargar ciudades
                this.$provinces.on('change', function() {
                    var province_id = $(this).val();
                    self.collectTowns(province_id);
                });
                // al cambiar de ciudad, cargar colegios
                this.$towns.on('change', function() {
                    var town_id = $(this).val();
                    self.collectSchools(town_id);
                });
            }
            $('#file-upload').on('change', function(e) {
                self.changeFileUpload($(this));
            });
            $('.client-face').on('mouseover', function() {
                self.faceCount++;
                if (self.faceCount === 35) {
                    self.justDoIt();
                }
            });
        },
        changeFileUpload: function ($el) {
            var val = $el.val();
            if (val != '') {
                var filedata = this.getFileName(val),
                    filename = filedata[0],
                    largename = filedata[1];
                $el.siblings('.file-upload-btn')
                    .removeClass('bg-secondary').addClass('bg-gray')
                    .siblings('.file-upload-path')
                        .text(filename)
                        .attr('title', largename);
            } else {
                $el.siblings('.file-upload-btn')
                    .removeClass('bg-gray').addClass('bg-secondary')
                    .siblings('.file-upload-path')
                        .text('')
                        .attr('title', '');
            }
        },
        getFileName: function (full_path) {
            var largename = full_path.split('/').pop().split('\\').pop(),
                filename = largename,
                lngth = filename.length;
            if (lngth > 40) {
                filename = '...' + filename.substr(lngth - 37, lngth);
            }
            return [filename, largename];
        },
        // busco las ciudades en la base de datos
        collectTowns: function (prov_id) {
            var self = this,
                $placeholder = this.$towns.find('option:first-child');
            this.$towns.html('') // borro las ciudades que había
                .append($placeholder) // pero dejo el placeholder
                .val(''); // y lo selecciono
            $.post("/api/towns", {'province_id': prov_id}, function(data) {
                $.each(data, function() {
                    self.addTown(this.name, this.id);
                });
            }, 'json');
        },
        // por cada ciudad, se ejecuta esto que la agrega al select
        addTown: function (name, id) {
            var option = '<option value="' + id + '">' + name + '</option>';
            this.$towns.append(option);
        },
        // busco las escuelas en la base de datos
        collectSchools: function (town_id) {
            var self = this;
            this.$schools_datalist.html(''); // borro las escuelas que había
            $.post("/api/department/schools", {'town_id': town_id}, function(data) {
                $.each(data, function() {
                    self.addSchool(this.name);
                });
            }, 'json');
        },
        // por cada escuela, se ejecuta esto que la agrega al select
        addSchool: function (name) {
            var option = '<option>' + name + '</option>';
            this.$schools_datalist.append(option);
        },
        // al enviar el formulario, valida
        validateForm: function () {
            var self = this,
                errors = false,
                error_msg;

            // this.clearMsgs();
            this.$form.find('[required]:enabled').not('.novalidate').each( function() {
                var value = $(this).val(),
                    valid = value != '' && value;

                if ($(this).hasClass('validate-min-pax')) {
                    valid = value && value >= self.min_pax;
                }

                if ( ! valid || ! value) {
                    errors = true;
                }

                $(this).toggleClass('is-invalid', ! valid);

            });

            return ! errors; // no errors = valid form
        },
        // todo ok por aca, manda el submit
        submitForm: function () {
            var self = this;
            this.toggleLoadingStatus(true);
            $.ajax({
                url: '//' + document.domain + this.post_url,
                type: "POST",
                headers: { 'X-CSRF-TOKEN': $('input[name="_token"]').val() },
                data: this.getFormData(),
                // data: new FormData(self.$form[0]),
                contentType: false,
                dataType: 'json',
                cache: false,
                processData:false,
                // se envio y volvio bien!
                success: function(res) {
                    if (res.success == 1) {
                        self.successForm();
                        if (typeof window.google_trackConversion !== 'undefined') {
                            window.google_trackConversion({
                                google_conversion_id: 942575401,
                                google_conversion_language: "en",
                                google_conversion_format: "3",
                                google_conversion_color: "ffffff",
                                google_conversion_label: "VmymCMGVl2oQqZ66wQM",
                                google_remarketing_only: false
                            });
                        }
                        if (typeof ga !== 'undefined') {
                            ga('send', 'event', 'form_contacto', 'enviar', 'ok');
                            console.log('ga');
                        }
                        if (typeof fbq !== 'undefined') {
                            fbq('track', 'CompleteRegistration');
                            console.log('fbq');
                        }
                        // self.changeFormBtnText('Gracias');
                    } else {
                        // error de validacion en v3, solo pasa con el phone invalido
                        // self.changeFormBtnText('Enviar');
                        // self.enableForm();
                        // self.clearErrors();
                        self.openMessage('Teléfono inválido. No pongas ni el 0 ni 15, código de área + núm. línea. Ejemplo: 1166770044', false);
                        // self.$form.find('[type="submit"]').attr('disabled', false);
                        self.toggleLoadingStatus(false);
                    }
                },
                // se envio y volvio con error
                error: function(res) {
                    self.toggleLoadingStatus(false);

                    if (res['responseJSON']) {
                        var text = '';
                        $.each(res['responseJSON'], function(k, msg) {
                            text += '<p>' + msg + '</p>';
                        });
                        self.openMessage(text, false);
                    } else {
                        self.openMessage('Error al enviar el formulario', false);
                    }
                },
                complete: function () {
                    // self.toggleLoadingStatus(false);
                }
            });
        },
        getFormData: function () {
            var formData = new FormData();
            $.each(this.$form.find('input, select, textarea'), function() {
                // salteamos este tipo de archivos
                if ($(this).hasClass('novalidate') || $(this).is('[disabled]')) {
                    return true;
                }
                var value = this.value;
                // si es un file, el value no es lineal https://stackoverflow.com/questions/21044798/how-to-use-formdata-for-ajax-file-upload
                if ($(this).attr('type') === 'file') {
                    value = this.files[0];
                }
                formData.append(this.name, value);
            });
            return formData;
        },
        toggleLoadingStatus: function (loading) {
            this.$form.find('[type="submit"]')
                .attr('disabled', loading)
                .toggleClass('spin-me', loading);
        },
        // muestra mensaje de que todo salio ok
        successForm: function () {
            this.openMessage(this.FORM_SUCCESS_MSG, true);
        },
        openMessage: function (text, success) {
            this.$messageWrapper.modal('show')
                .find('.modal-content')
                .toggleClass('bg-success', success)
                .toggleClass('bg-danger', !success)
                    .find('.form-message')
                    .html(text);
        },
        justDoIt: function () {
            var $wrapper = $('<div/>', {
                css: {
                    display: 'flex',
                    'align-items': 'center',
                    'justify-content': 'center',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 9999,
                    background: 'rgba(0, 0, 0, 0.6)',
                    opacity: 0,
                    transition: 'all 0.4s ease',
                    color: '#fff',
                    fontSize: '3rem',
                    fontWeight: 'bold'
                }
            });
            $wrapper.appendTo($('body'));
            setTimeout(function () {
                $wrapper.css('opacity', 1);
            }, 400);
            setTimeout(function () {
                $wrapper.html('PARAAAAAA');
            }, 800);
            setTimeout(function () {
                for (var i = 0; i < 55; i++) {
                    setTimeout(function () {
                        $wrapper[0].innerHTML += 'A';
                    }, i * 30);
                    setTimeout(function () {
                        $wrapper.fadeOut(400, function() {
                            $(this).remove();
                        });
                    }, 55 * 30);
                }
            }, 1800);
            this.faceCount = 0;
        }
    };

    /* Wrapper de jQuery */
    $.fn.ContactForm = function (options) {
        return this.each(function () {
            var data = $(this).data('lb.form');
            if ( ! data) {
                $(this).data(
                    'lb.form', // namespace LB
                    (data = new Form(this, $.extend({}, $.fn.ContactForm.defaults, options)))
                );
            }
        });
    };

    /* Default options */
    $.fn.ContactForm.defaults = {
        $messageWrapper: $('.form-message-wrapper'),
        EMPTY_ERR_MSG: 'Tenés que completar tu',
        FORM_SUCCESS_MSG: 'Tu consulta fue enviada!',
        with_location: false,
        min_pax: 13,
        // simple_form: false,
        post_url: '/contacto',
        allowDefault: false
    };

}(jQuery));