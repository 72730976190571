(function ($) {

    "use strict";

    var Scroll = {
        $links: $('a.scroll'),
        init: function () {
            this.bindEvents();
        },
        bindEvents: function () {
            var self = this;
            this.$links.on('click', function(e) {
                var navHeight = $('.navbar-main').outerHeight() + parseInt($('.navbar-main').css('margin-bottom')),
                    el = $(this).attr('href');
                e.preventDefault();
                if ($(el).length) {
                    $('html, body').animate({
                        scrollTop: $(el).offset().top - navHeight
                    }, 400)
                }
            });
        }
    };

    Scroll.init();
}(jQuery));