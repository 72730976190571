(function () {
    var copyToClipboard = {
        $clipboardInput: $('.clipboard-text'),
        $clipboardBtn: $('.clipboard-btn'),
        executed: 0,
        init: function () {
            if (this.$clipboardBtn.length && this.$clipboardInput.length) {
                this.$clipboardBtn.tooltip();
                this.tooltipText = this.$clipboardBtn.attr('title');
                this.bindEvents();
            }
        },
        bindEvents: function () {
            var self = this;
            this.$clipboardBtn.on('click', function() {
                self.doClipboard();
            });
        },
        doClipboard: function () {
            this.$clipboardInput.select();
            try {
                if (document.execCommand('copy')) {
                    this.handleClipboardSuccess();
                } else {
                    this.handleClipboardError();
                }
            } catch (err) {
                this.handleClipboardError();
            }
        },
        handleClipboardSuccess: function () {
            this.executed++;
            var self = this,
                current = this.executed;
            this.refreshTooltipText('Copiado! :)', true);
            setTimeout(function () {
                if (current === self.executed) self.refreshTooltipText(self.tooltipText);
            }, 2300);
        },
        handleClipboardError: function () {
            this.executed++;
            var self = this,
                current = this.executed;
            this.refreshTooltipText('No se pudo copiar. Hacelo manualmente!', true);
            setTimeout(function () {
                if (current === self.executed) self.refreshTooltipText(self.tooltipText);
            }, 2300);
        },
        refreshTooltipText: function (text, show) {
            this.$clipboardBtn
                .tooltip('hide')
                .attr('data-original-title', text);
            if (show) {
                this.$clipboardBtn.tooltip('show');
            }
        }
    };
    copyToClipboard.init();
}(window));