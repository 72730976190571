(function ($) {

    "use strict";

    var menu = {
        $btn: $('[href="#main-menu"]'),
        $menu: $('.navbar-main'),
        $link: $('.navbar-main .nav-link'),
        init: function () {
            var self = this;
            this.$btn.on('click', function(e) {
                e.preventDefault();
                self.toggleMenu();
                // self.closeSubmenu();
            });
            // this.$link.on('click', function(e) {
            //     var $item = $(this).parents('.nav-item');
            //     if (window.innerWidth < 992 && $item.length) {
            //         e.preventDefault();
            //         self.toggleSubmenu($item);
            //     }
            // });
        },
        toggleMenu: function () {
            $('body').toggleClass('menu-open');
            this.$menu.toggleClass('is-active');
        },
        // toggleSubmenu: function ($item) {
        //     this.closeSubmenuExcept($item);
        //     $item.toggleClass('is-open');
        // },
        // closeSubmenu: function () {
        //     $('.navbar-main .nav-item').removeClass('is-open');
        // },
        // closeSubmenuExcept: function ($item) {
        //     $('.navbar-main .nav-item').not($item).removeClass('is-open');
        // }
    };

    menu.init();
}(jQuery));